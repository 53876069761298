import GithubRepo from '../../components/GithubRepo/GithubRepo'

const NotesHome = () => {

  return (
    <div>
      <GithubRepo repoName={"notes"} repoOwner={"elimelt"}/>
    </div>
  )
}

export default NotesHome
